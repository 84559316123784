









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsLangSwitch extends Vue {
  changeLang (lang) {
    this.$Api.member
      .setUILanguage(lang)
      .then(result => {
        this.$i18n.locale = lang;
        localStorage.setItem('locale', lang);

        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
